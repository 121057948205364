var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "refer-afriend-container" },
    [
      _c("PageLayout", {
        scopedSlots: _vm._u([
          {
            key: "page-name",
            fn: function () {
              return [
                _c("div", { staticClass: "copy-notification-wrapper" }, [
                  _c(
                    "div",
                    {
                      staticClass: "copy-notification",
                      class: { show: _vm.showNotif == true },
                    },
                    [
                      _c("div", { attrs: { id: "img" } }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/01-Menu/icon-checkmark-circle-white.svg"),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("div", { attrs: { id: "desc" } }, [
                        _vm._v("Copied successfully"),
                      ]),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "nav-title font-menu-large" },
                  _vm._l(_vm.navList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "nav-title-item",
                        class: { active: item.id === _vm.currentPage },
                        on: {
                          click: function ($event) {
                            return _vm.changePage(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "page-content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "refer-title handle-word-wrap font-menu-large",
                    },
                    [_vm._v(" Invite your friends and get FREE CLASSES ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "description line-word-overflow-wrap font-menu-medium",
                    },
                    [
                      _vm._v(
                        " Would you like to get a free NihaoKids class for your child? It's easy — just refer a friend! The more friends you invite, the more free classes you will get. "
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "step-box" }, [
                    _c("div", { staticClass: "refer-img" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/05-Panda/panda-waving.svg"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "steps-one" }, [
                      _c("span", { staticClass: "speech-bubble-origin" }, [
                        _c("img", {
                          attrs: {
                            src: require(`@/assets/img/01-Menu/speech-bubble-origin-left.svg`),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "line-dot" }),
                      _c("span", { staticClass: "line-hide" }),
                      _c("span", { staticClass: "line" }),
                      _c(
                        "div",
                        {
                          staticClass: "title handle-word-wrap font-menu-large",
                        },
                        [_vm._v(" Share your referral link ")]
                      ),
                      _c("p", { staticClass: "font-menu-medium" }, [
                        _vm._v(
                          " To share NihaoKids with your friend(s), copy the unique link below. This way we will know that you have referred them, and you'll be able to claim your free class. "
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "share-by-link handle-word-wrap font-menu-medium",
                          on: { click: _vm.copyLinkClipboard },
                        },
                        [_vm._v(" Copy the link ")]
                      ),
                    ]),
                    _c("div", { staticClass: "steps-two" }, [
                      _c("span", { staticClass: "speech-bubble-origin" }, [
                        _c("img", {
                          attrs: {
                            src: require(`@/assets/img/01-Menu/speech-bubble-origin-left.svg`),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "line-dot" }),
                      _c("span", { staticClass: "line" }),
                      _c(
                        "div",
                        {
                          staticClass: "title handle-word-wrap font-menu-large",
                        },
                        [_vm._v(" You and your friend will get a FREE class ")]
                      ),
                      _c("p", { staticClass: "font-menu-medium" }, [
                        _vm._v(
                          " Once your friend(s) sign up for a free class, we will receive a notification that you are eligible for a free class, and our course consultants will get in touch with you promptly. "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "steps-three" }, [
                      _c("span", { staticClass: "speech-bubble-origin" }, [
                        _c("img", {
                          attrs: {
                            src: require(`@/assets/img/01-Menu/speech-bubble-origin-left.svg`),
                            alt: "",
                          },
                        }),
                      ]),
                      _c("span", { staticClass: "line-dot" }),
                      _c("span", { staticClass: "line-hide" }),
                      _c("span", { staticClass: "line" }),
                      _c(
                        "div",
                        {
                          staticClass: "title handle-word-wrap font-menu-large",
                        },
                        [_vm._v(" Earn 5 FREE 1-on-1 classes ")]
                      ),
                      _c("p", { staticClass: "font-menu-medium" }, [
                        _vm._v(
                          " ...and there is more! If after a trial your friends choose to sign up and purchase 32 classes or more, you will get a bonus of extra 5 classes. You can check the status of your referrals in the table below. "
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "information font-menu-small" },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.tableData,
                            "row-class-name": "row-style",
                            "cell-class-name": "cell-style font-menu-medium",
                            "header-cell-class-name":
                              "header-cell-style font-menu-small",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "Name",
                              width: "140",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "joinDate",
                              label: "Join Date",
                              width: "140",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "trialDate",
                              label: "Trial Date",
                              width: "140",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "purchasedDate",
                              label: "Purchased Date",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "purchasedClasses",
                              label: "# Purchased classes",
                              width: "200",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "bouns", label: "Your Bonus" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }